<template>
  <div class="filter-box">
    <div class="first-type" v-for="(first, index) in typeList" :key="index">
      <div v-if="config[first.code]" class="type-item">
        <div class="left">{{first.name}}</div>
        <div class="right">
          <div
            v-for="(keywords, i) in first.val"
            :key="i"
            :class="{'key-item': true, 'active': first.current == keywords.val}"
            @click="handleItemClick(first, keywords.val, first.code)"
          >{{keywords.name}}</div>
        </div>
      </div>
    </div>
    <div class="search">
      <!-- 排序 -->
      <div class="comprehensive-sort" v-if="showSortable()">
        <div class="type-item">
          <div class="left">综合排序:</div>
          <div class="right">
            <div class="key-item" v-for="(item, index) in  sortList" :key="index">
              <div v-if="sortable[item.code]">
                <span
                  @click="sort(item, index)"
                  :class="{'strong': current === index}"
                >{{ item.label }}</span>
                <div class="sort" v-if="item.sort">
                  <i
                    class="el-icon-caret-top"
                    @click.stop="upDown(item, index, 'asc')"
                    :class="{'strong': current === index}"
                  ></i>
                  <i class="el-icon-caret-bottom" @click.stop="upDown(item, index, 'desc')"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 搜索 -->
      <div class="searchable" v-if="hasSearch">
        <el-input
          placeholder="请输检索关键字"
          prefix-icon="el-icon-search"
          @input="search"
          clearable
          v-model="form.keywords"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'topFilter',
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          time: false,
          area: false,
          technical: false,
          industry: false,
          instrument: false,
          experiment: false,
          //期望职位
          jobs: false,
          positionLocation: false,
          policytype: false,
          dsType: false
        }
      }
    },
    sortable: {
      type: Object,
      default: () => {
        return {
          new: false,
          hot: false,
          release: false,
          quota: false,
          limit: false
        }
      }
    },
    hasSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typeList: [
        {
          name: '时间筛选',
          code: 'time',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '最近一周',
              val: 1,
            },
            {
              name: '最近一月',
              val: 2,
            },
            {
              name: '最近半年',
              val: 3,
            }
          ]
        },
        {
          name: '地区筛选',
          code: 'area',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '北京',
              val: 1,
            },
            {
              name: '上海',
              val: 2,
            },
            {
              name: '广州',
              val: 3,
            }
          ]
        },
        {
          name: '技术领域',
          code: 'technical',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '电子信息技术',
              val: 1,
            },
            {
              name: '先进材料',
              val: 2,
            },
            {
              name: '装备制造',
              val: 3,
            },
            {
              name: '生物医药',
              val: 4,
            },
            {
              name: '节能环保',
              val: 5,
            },
            {
              name: '汽车行业',
              val: 6,
            },
            {
              name: '航空航天技术',
              val: 7,
            },
            {
              name: '食品饮料',
              val: 8,
            },
            {
              name: '其他',
              val: 9,
            }
          ]
        },
        {
          name: '行业领域',
          code: 'industry',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '制造业',
              val: 1,
            },
            {
              name: '教育',
              val: 2,
            }
          ]
        },
        {
          name: '仪器类别',
          code: 'instrument',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '热门仪器',
              val: 1,
            },
            {
              name: '二手仪器',
              val: 2,
            },
            {
              name: '租赁仪器',
              val: 3,
            },
            {
              name: '其他',
              val: 4,
            }
          ]
        },
        {
          name: '仪器类别',
          code: 'experiment',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '离心试验',
              val: 1,
            },
            {
              name: '振动试验',
              val: 2,
            },
            {
              name: '风洞试验',
              val: 3,
            },
            {
              name: '高空台试验',
              val: 4,
            },
            {
              name: '冲击试验',
              val: 5,
            },
            {
              name: '星光激光装置试验',
              val: 6
            }
          ]
        },
        {
          name: '仪器类别',
          code: 'ins',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '电子仪器',
              val: 1,
            },
            {
              name: '工程检测',
              val: 2,
            },
            {
              name: '软件测试',
              val: 3,
            },
            {
              name: '计量校准服务',
              val: 4,
            },
            {
              name: '材料检测',
              val: 5,
            },
            {
              name: '其他服务',
              val: 6,
            }
          ]
        },
        {
          name: '期望职位',
          code: 'jobs',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '经营管理',
              val: 1,
            },
            {
              name: '计算机/互联网/通信',
              val: 2,
            },
            {
              name: '市场/公关',
              val: 3,
            },
            {
              name: '通信',
              val: 4,
            },
            {
              name: '美术/设计/创意',
              val: 5,
            },
            {
              name: '汽车行业',
              val: 6,
            },
            {
              name: '机械/仪表',
              val: 7,
            },
            {
              name: '食品饮料',
              val: 8,
            },
            {
              name: '其他',
              val: 9,
            }
          ]
        },
        {
          name: '地区筛选',
          code: 'positionLocation',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '涪城区',
              val: 1,
            },
            {
              name: '游仙区',
              val: 2,
            },
            {
              name: '安州区',
              val: 3,
            },
            {
              name: '三台县',
              val: 4,
            },
            {
              name: '盐亭县',
              val: 5,
            },
            {
              name: '梓潼县',
              val: 6,
            },
            {
              name: '北川羌族自治县',
              val: 7,
            },
            {
              name: '平武县',
              val: 8,
            },
            {
              name: '江油市',
              val: 9,
            }
          ]
        },
        {
          name: '政策分类',
          code: 'policytype',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '国家政策',
              val: 1,
            },
            {
              name: '省级政策',
              val: 2,
            },
            {
              name: '市级政策',
              val: 3,
            }
          ]
        },
        {
          name: '服务分类',
          code: 'dsType',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '环境试验',
              val: 1,
            },
            {
              name: '并行/仿真计算',
              val: 2,
            },
            {
              name: '算力服务',
              val: 3,
            }
          ]
        },
        {
          name: '仪器类别',
          code: 'bigType',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '大科学装置',
              val: 1,
            },
            {
              name: '大型仪器',
              val: 2,
            },
            {
              name: '仪器商城',
              val: 3,
            },
            {
              name: '仪器研究',
              val: 4,
            }
          ]
        },
        {
          name: '单位类别',
          code: 'friends',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '军工单位',
              val: 1,
            },
            {
              name: '科研院所',
              val: 2,
            },
            {
              name: '大型企业',
              val: 3,
            },
            {
              name: '高等院校',
              val: 4,
            },
            {
              name: '高新技术企业',
              val: 5,
            },
            {
              name: '医疗机构',
              val: 6,
            },
            {
              name: '检测机构',
              val: 7,
            },
            {
              name: '军民融合企业',
              val: 8,
            },
            {
              name: '政府机构',
              val: 9,
            },
            {
              name: '民营企业',
              val: 10,
            }
          ]
        },
        {
          name: '所在地区',
          code: 'atArea',
          current: null,
          val: [
            {
              name: '不限',
              val: null,
            },
            {
              name: '西南',
              val: 1,
            },
            {
              name: '西北',
              val: 2,
            },
            {
              name: '华中',
              val: 3,
            },
            {
              name: '华东',
              val: 4,
            },
            {
              name: '华南',
              val: 5,
            },
            {
              name: '华北',
              val: 6,
            },
            {
              name: '东北',
              val: 7,
            }
          ]
        }
      ],
      form: {},
      sortList: [
        {
          label: '最新',
          code: 'new',
          sort: false,
          val: 2
        },
        // {
        //   label: '最热',
        //   code: 'hot',
        //   sort: false,
        //   val: 1
        // },
        {
          label: '发布时间',
          code: 'release',
          sort: true
        },
        {
          label: '最高额度',
          code: 'quota',
          sort: true
        },
        {
          label: '贷款期限',
          code: 'limit',
          sort: true
        }
      ],
      current: null,
      timer: null
    }
  },
  computed: {
  },
  mounted() {
    // this.showSortable()
  },
  methods: {
    handleItemClick(type, val, code) {
      type.current = val
      this.form[code] = val
      this.$emit('handleSearchParams', this.form)
    },
    showSortable() {
      for (let k in this.sortable) {
        if (this.sortable[k]) {
          return true;
        }
      }
      return false
    },
    search(val) {
      if (this.timer != null) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('handleSearchParams', this.form)
      }, 500)
    },
    sort(item, index) {
      this.current = index;
      if (item.sort) return;
      this.form.sort = item.val
      this.$emit('handleSearchParams', this.form)
    },
    upDown(item, index, position) {
      this.current = index;
      this.form.orderBy = item.code
      this.form.orderType = position
      this.$emit('handleSearchParams', this.form)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.filter-box {
  font-size: 15px;
  padding-top: 20px;
  .type-item {
    display: flex;
    line-height: 25px;
    .left {
      font-weight: 700;
      margin-right: 10px;
      min-width: 80px;
      color: #000;
    }
    .right {
      display: flex;
      flex-wrap: wrap;
      .key-item {
        padding: 0 15px;
        margin: 0 15px 15px 0;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        .sort {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-top: -4px;
          i {
            padding: 0 10px;
            height: 10px;
          }
          i:hover {
            color: #0046c0;
          }
        }
      }
      .key-item.active {
        background-color: #0046c0;
        color: #fff;
      }
    }
    .strong {
      font-weight: 700;
      color: #333;
    }
  }
  .search {
    display: flex;
    justify-content: space-between;
    .comprehensive-sort {
      padding-top: 30px;
    }
    .searchable {
      width: 240px;
      padding-top: 30px;
    }
  }
}
</style>
