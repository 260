<template>
  <div :class="['cell-wrapper', hideDetailButton == true ? 'cell-wrapper-disabled' : '']">
    <div class="left">
      <div class="title h2">{{ info.positionName }}</div>
      <div class="condition">
        <div class="meet">{{ salary }}</div>
        <div class="tag">{{ info.base }} | {{ education }} | {{ experience }}</div>
      </div>
    </div>
    <div class="middle">
      <img class="image" src="../../../static/images/rencaizhaopinhome.png" />
      <div class="content">
        <div class="h2 single-line company-name">
          <div>{{ info.companyName }}</div>
          <div class="zheng">证</div>
        </div>
        <div>{{ positionIndustry }} | {{ info.companyNature }}</div>
      </div>
    </div>
    <div class="button" @click="handleClick" v-if="!hideDetailButton">点击查看</div>
  </div>
</template>

<script>
export default {
  props: ['info', 'hideDetailButton'],
  data() {
    return {}
  },
  computed: {
    salary() {
      const v = this.info?.salary
      if (v == '1') {
        return '面议'
      }
      if (v == '2') {
        return '3000-6000'
      }
      if (v == '3') {
        return '6000-10000'
      }
      if (v == '4') {
        return '10000-20000'
      }
      return '--'
    },
    education() {
      const v = this.info?.education
      if (v == '1') {
        return '学历不限'
      }
      if (v == '2') {
        return '专科及以上'
      }
      if (v == '3') {
        return '本科及以上'
      }
      if (v == '4') {
        return '硕士及以上'
      }
      if (v == '5') {
        return '博士'
      }
      return '--'
    },
    experience() {
      const v = this.info?.experience
      if (v == '1') {
        return '经验不限'
      }
      if (v == '2') {
        return '3年以内'
      }
      if (v == '3') {
        return '3-5年'
      }
      if (v == '4') {
        return '5年以上'
      }
      return '--'
    },
    positionIndustry() {
      const v = this.info?.positionIndustry
      if (v == '1') {
        return '不限'
      }
      if (v == '2') {
        return '计算机/互联网/通信'
      }
      if (v == '3') {
        return '市场/公关'
      }
      if (v == '4') {
        return '销售'
      }
      if (v == '5') {
        return '美术/设计/创意'
      }
      if (v == '6') {
        return '汽车行业'
      }
      if (v == '7') {
        return '机械/仪器仪表'
      }
      if (v == '8') {
        return '食品饮料'
      }
      if (v == '9') {
        return '其他'
      }
      return '--'
    },
  },
  created() { },
  mounted() {

  },
  methods: {
    handleClick() {
      this.$emit('handleClick', this.info)
    },
  }
}
</script>

<style scoped lang="less">
.cell-wrapper {
  height: 110px;
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: #dfdfdf 1px solid;
  // background-color: #eef8ff;

  .left {
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .title {
      cursor: pointer;
    }

    .condition {
      display: flex;
      align-items: center;
      margin-top: 20px;
      flex-wrap: wrap;

      .meet {
        color: #d91d40;
        margin-right: 20px;
      }
    }
  }

  .middle {
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .image {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }

    .content {
      display: flex;
      flex-direction: column;

      .company-name {
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .zheng {
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          background-color: #00aa00;
          font-size: 16px;
          color: #fff;
          margin: 2px 0 0 2px;
        }
      }

      .tag {
        padding-right: 10px;
        border-right: 1px #dfdfdf solid;
        margin-right: 10px;
      }
    }
  }

  .button {
    text-align: center;
    height: 42px;
    width: 150px;
    border: solid 1px #dfdfdf;
    line-height: 42px;
    cursor: pointer;
    margin-left: 20px;
    border-radius: 15px;
  }
}

.cell-wrapper:hover:not(.cell-wrapper-disabled) {
  // color: #27A5F9;
  background: #eef8ff;

  // transform: scaleX(1.045);
  .button {
    background-color: #0046c0;
    color: #fff;
  }
}
</style>
