<template>
  <div class="menu-box">
    <div class="h2">{{title}}</div>
    <div class="list">
      <div
        :class="{'menu-item': true, 'active': current == index}" 
        v-for="(item, index) in menuData" 
        :key="index"
        @click="handleClick(item, index)"
        >{{item.name}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerticalMenu',
  props: {
    title: {
      type: String,
      default: '云上招引'
    },
    menuData: {
      type: Array,
      default: () => {
        return [
          // {
          //   name: 'lalalala',
          //   code: '111'
          // }
        ]
      }
    },
	defaultCode:{
      type: String,
      default: null
    },
  },
  data() {
    return {
      current: 0
    }
  },
  computed:{

  },
  mounted() {
    if (this.defaultCode){
		for (var i = 0; i < this.menuData.length; i++) {
			if (this.menuData[i]?.code==this.defaultCode){
				this.current = i
				break;
			}
		}
	}
  },
  methods: {
    handleClick(item, index) {
      this.current = index
      this.$emit('handleMenuChange', item)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.menu-box {
  background-color: #fff;
  width: 240px;
  .h2 {
    text-indent: 40px;
    line-height: 3;
    font-size: 18px;
    // padding-bottom: 10px;
    color: #3F536E;
  }
  .list {
    min-height: 300px;
	  // padding-left: 20px;
    .menu-item {
      position: relative;
      line-height: 3;
      border-left: 3px solid #fff;
      text-indent: 57px;
      cursor: pointer;
      font-size: 16px;
    }
    .menu-item:hover {
      color: #27A5F9;
    }
    .menu-item::after {
      position: absolute;
      top: 20px;
      left: 40px;
      height: 4px;
      width: 4px;
      content: "";
      border-radius: 50%;
      background: #666;
    }
    .menu-item.active {
      border-left: 3px solid #27A5F9;
      color: #27A5F9;
      background: #EEF8FF;
      &::after{
        background: #27A5F9;
      }
    }  
  }
}
</style>
