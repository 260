<template>
  <div class="list">
    <div class="item" v-for="(item, index) in data" :key="index" @click="handleClick(item)">
      <div class="item-title">{{item.title}}</div>
      <div class="time">{{timeStr(item)}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleList',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    handleClick(item) {
      this.$emit('handleClick', item)
    },
    timeStr(item) {
      var t = item.time
      if (!t) {
        t = item.createTime
      }
      return t ? t.substring(0, 10) : '--';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.list {
  padding: 20px;
  background-color: #fff;
  font-size: 16px;
  .item {
    position: relative;
    border-bottom: 1px solid #eee;
    padding: 30px 20px 30px 30px;
    cursor: pointer;
    .time {
      width: 120px;
      display: inline-block;
      text-align: right;
    }
    .item-title {
      display: inline-block;
      width: calc(100% - 130px);
      margin-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .item::after {
    position: absolute;
    top: 40px;
    left: 20px;
    height: 4px;
    width: 4px;
    content: "";
    border-radius: 50%;
    background: #666;
  }
  .item:hover {
    color: #27a5f9;
    background: #eef8ff;
    transform: scaleX(1.045);
  }
}
</style>
