<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="flex-box">
        <vertical-menu
          class="left-menu-box"
          title="招才引智"
          :menuData="menuData"
          @handleMenuChange="handleMenuChange"
          :defaultCode="menu_id"
        ></vertical-menu>
        <!-- 政策 -->
        <div class="right-list-box" v-if="menu_id=='zhengcezhichi'">
          <article-list @handleClick="articleClick" :data="dataList1"></article-list>
          <div class="footer">
            <div class="left">共 {{total1}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total1"
              :current-page.sync="queryParams1.pageNum"
              :page-size="queryParams1.pageSize"
              @current-change="getList1"
            ></el-pagination>
          </div>
        </div>
        <!-- 人才招聘 -->
        <div class="right-list-box" v-if="menu_id=='rencaizhaopin'" style="padding: 20px;">
          <top-filter
            :config="{'positionLocation':true,'jobs':true}"
            :sortable="{new: true, hot: true}"
            :hasSearch="true"
            @handleSearch="getList2ByFilter"
            @handleSearchParams="getList2ByFilter"
          ></top-filter>
          <div class="zhaopin-list">
            <cell-rencaizhaopin
              v-for="item in dataList2"
              :key="item.id"
              @handleClick="rencaiDetail"
              :info="item"
            ></cell-rencaizhaopin>
            <div class="footer">
              <div class="left">共 {{total2}} 条</div>
              <el-pagination
                background
                layout="prev, pager, next"
                :total="total2"
                :current-page.sync="queryParams2.pageNum"
                :page-size="queryParams2.pageSize"
                @current-change="getList2"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'
import topFilter from '@/components/topFilter.vue'
import cellRencaizhaopin from './components/cell-rencaizhaopin.vue'
export default {
  name: 'Home',
  components: {
    articleList,
    verticalMenu,
    topFilter,
    cellRencaizhaopin
  },
  data() {
    return {
      menuData: [{
        'name': '政策支持',
        'code': 'zhengcezhichi'
      },
      {
        'name': '人才招聘',
        'code': 'rencaizhaopin'
      }
      ],
      menu_id: 'zhengcezhichi',
      form: {},
      dataList1: [],
      queryParams1: {
        pageNum: 1,
        pageSize: 6,
      },
      total1: 0,
      dataList2: [],
      queryParams2: {
        pageNum: 1,
        pageSize: 6,
      },
      total2: 0,
    }
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const menu_id = this.$route.query?.menu_id
    if (menu_id&&menu_id!='undefined') {
      this.menu_id = menu_id
    }
  },
  mounted() {
    this.getList1()
    this.getList2()
  },
  methods: {
    getList1() {
      const params = {
        ...this.queryParams1,
        'areaType': '1',
        'policyType': '1',
      }
      this.$api.solicitationCloud.talentPolicyitList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList1 = data.rows;
          this.total1 = data.total;
        }
      })
    },
    getList2ByFilter(v) {
      this.queryParams2['positionIndustry'] = v?.jobs
      this.queryParams2['positionLocation'] = v?.positionLocation
      this.queryParams2['keywords'] = v?.keyword
      this.queryParams2['sort'] = v?.sort
      console.log('v = ', v)
      this.getList2()
    },
    getList2() {
      const params = {
        ...this.queryParams2,
      }
      this.$api.solicitationCloud.talentRecruitList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList2 = data.rows;
          this.total2 = data.total;
        }
      })
    },
    handleMenuChange(item) {
      console.log('handleMenuChange = ', item)
      this.menu_id = item.code
    },
    goback() {
      this.$router.back()
    },
    articleClick(item) {
      this.$router.push({ path: '/solicitationCloud/page-zcyz/articledetail', query: { id: item.id, 'detailType': 'soli-zhaocaizhengce' } })
    },
    rencaiDetail(item) {
      this.$router.push('/solicitationCloud/page-zcyz/detail-rencaizhaopin?id=' + item.id)
    },
    onSubmit() { },

  }
}
</script>
<style scoped lang="less">
.zhaopin-list {
  display: flex;
  flex-direction: column;
  padding: 30px;

  .item-wrapper {
    display: flex;
    height: 50px;
    background-color: aliceblue;
    border-bottom: #dfdfdf 1px solid;
  }
}
</style>
